<template>
  <section
    :class="$customSectionClasses(props.cfgs)"
    class="flat-filter-root"
  >
    <div class="container">
      <div class="home-filter">
        <LazyFilterHome
          :slug="props.extras.slug"
          :url="props.extras.url"
          :product-type="(props.values?.type_products || 0)"
          :category-id="Number(values.category_id)"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import LazyFilterHome from '~/components/home/LazyFilterHome.vue';
import type { SectionTypes } from '~/plugins/customSectionClasses';
import type { IBlockDefaultProps } from '#sitis/internal/models/common';

const { $customSectionClasses } = useNuxtApp();
type FlatFilterBlockType = Omit<IBlockDefaultProps, 'values' | 'cfgs' | 'extras'> & {
	values: {
		title?: string;
		category_id: string;
		filter_page_id: number;
		type_products: number;
	};
	cfgs: SectionTypes;
	extras: {
		slug: string;
		url: string;
	}
}

const props = defineProps<FlatFilterBlockType>();
</script>

<style lang="scss">
@import "@/assets/scss/media";

.flat-filter-root {
  z-index: 11;
}
</style>
